import { Controller } from "@hotwired/stimulus";
class src_default extends Controller {
  initialize() {
    this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this);
  }
  connect() {
    this.class = this.classValue || this.defaultOptions.class || "in";
    this.threshold = this.thresholdValue || this.defaultOptions.threshold || 0.1;
    this.rootMargin = this.rootMarginValue || this.defaultOptions.rootMargin || "0px";
    this.observer = new IntersectionObserver(this.intersectionObserverCallback, this.intersectionObserverOptions);
    this.itemTargets.forEach((item) => this.observer.observe(item));
  }
  disconnect() {
    this.itemTargets.forEach((item) => this.observer.unobserve(item));
  }
  intersectionObserverCallback(entries, observer) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > this.threshold) {
        const target = entry.target;
        target.classList.add(this.class);
        if (target.dataset.delay) {
          target.style.transitionDelay = target.dataset.delay;
        }
        observer.unobserve(target);
      }
    });
  }
  get intersectionObserverOptions() {
    return {
      threshold: this.threshold,
      rootMargin: this.rootMargin
    };
  }
  get defaultOptions() {
    return {};
  }
}
src_default.targets = ["item"];
src_default.values = {
  class: String,
  threshold: Number,
  rootMargin: String
};
export { src_default as default };
